import { AsyncPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, switchMap } from "rxjs";
import { take, tap } from "rxjs/operators";

import { PrizeAmountService } from "@common-services/prize-amount.service";
import { TenantService } from "@common-services/tenant.services";
import { BaseButtonComponent } from "@components/base-button/base-button.component";
import { TimerComponent } from "@components/timer/timer.component";
import { BracketTypeEnum } from "@enums/BracketTypeEnum";
import { GameStatusEnum } from "@enums/GameStatusEnum";
import { BracketGameModel } from "@models/BracketGameModel";
import { FormatThousandsNumberPipe } from "@modules/pipes/pipes/format-number.pipe";
import { BracketGameService } from "@services/bracket-game.service";
import { CmsContentService } from "@services/cms-content.service";


@Component({
    selector: 'hun-landing-bracket-round-info',
    templateUrl: './landing-bracket-round-info.component.html',
    styleUrls: ['./landing-bracket-round-info.component.scss'],
    standalone: true,
    imports: [
        BaseButtonComponent,
        TimerComponent,
        AsyncPipe,
        FormatThousandsNumberPipe,
    ],
})
export class LandingBracketRoundInfoComponent implements OnInit {

  @Input() roundInfo: BracketGameModel;

  @Input() activeType: BracketTypeEnum;

  prizeAmount: string = '';

  protected readonly GameStatus = GameStatusEnum;

  protected readonly BracketTypeEnum = BracketTypeEnum;

  public unsubscribe$ = new Subject<void>();

	constructor(
    public cmsContentService: CmsContentService,
    public bracketGameService: BracketGameService,
    public tenantService: TenantService,
    public prizeAmountService: PrizeAmountService,
    private router: Router
	)
	{}

  ngOnInit() {
    this.cmsContentService.cmsContent$.pipe(
      take(1),
      switchMap(content => {
        return this.prizeAmountService.getSinglePrizeAmount(
          content['text_content']['iframe-v3-bracket-amount-landing'],
          content['text_content']['iframe-v3-bracket-currency'],
          true)
      }),
      tap((prizeAmount) =>  this.prizeAmount = prizeAmount)
    ).subscribe();
  }


  onRoundClick() {
    if (this.roundInfo.activeType === BracketTypeEnum.GROUP) {
      this.router.navigate([`frame/bracket/group-stage`]);
    } else if (this.roundInfo.activeType === BracketTypeEnum.KNOCKOUT) {
      this.router.navigate([`frame/bracket/knockout-stage`]);
    }
  }

}
