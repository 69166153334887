import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";

import { BaseButtonComponent } from "@components/base-button/base-button.component";
import { TimerComponent } from "@components/timer/timer.component";
import { FormatThousandsNumberPipe } from "@modules/pipes/pipes/format-number.pipe";
import { CmsContentService } from "@services/cms-content.service";


@Component({
    selector: 'hun-landing-how-to-play-info',
    templateUrl: './landing-how-to-play-info.component.html',
    styleUrls: ['./landing-how-to-play-info.component.scss'],
    standalone: true,
    imports: [
        BaseButtonComponent,
        TimerComponent,
        AsyncPipe,
        FormatThousandsNumberPipe,
    ],
})
export class LandingHowToPlayInfoComponent {

  @Input() info: { title: string, description: string};

	constructor(
    public cmsContentService: CmsContentService
	)
	{}
}
