import { Platform } from "@angular/cdk/platform";
import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatTabLink, MatTabNav, MatTabNavPanel } from "@angular/material/tabs";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, takeUntil, tap } from "rxjs/operators";

import { FRAME_DATA } from "@app/app-common/frame.config";
import { IsDesktopService }  from "@common-services/is-desktop.services";
import { ModalWindowService } from "@common-services/modal-window.service";
import { MyPredictionsService } from "@common-services/my-predictions.service";
import { ScrollService }  from "@common-services/scroll.service";
import { SupportDvhService } from "@common-services/support-dvh.service";
import { CmsContentService } from "@services/cms-content.service";
import { DecorElementsService } from "@services/decor-elements.service";
import { UserService } from "@services/user.service";


@Component({
  selector: 'hun-menu-bracket',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [
    AsyncPipe,
    InfiniteScrollDirective,
    MatTabLink,
    MatTabNav,
    MatTabNavPanel,
    RouterLink,
    NgStyle,
    NgClass
  ],
  standalone: true
})
export class MenuComponent implements OnInit {

  @Input() hideInfoButton = false;

  isLandingPage$: Observable<boolean>;

  isLockPage$: Observable<boolean>;

  contentHeight$ = new Observable<string>();

  isSupportDVH = true;

  unsubscribe$: Subject<void> = new Subject<void>();

  isDesktop: boolean;

  containerClassList: string;

  globalScrollContainerClassList: string;

  wrapperScrollContainerClassList: string;

  private _lastHeight = '';

  private _lastResult: any;

  constructor(private modalWindowService: ModalWindowService,
               public scrollService: ScrollService,
               public myPredictionsService: MyPredictionsService,
               public isDesktopService: IsDesktopService,
               public cmsContentService: CmsContentService,
               public platform: Platform,
               public router: Router,
               public userService: UserService,
               private supportDvhService: SupportDvhService,
               public decorElementsService: DecorElementsService) {}


  get containerHeightStyle(): { [key: string]: string } {
    const height = this.platform.ANDROID ? '190vw' : this.isSupportDVH ? '100dvh' : '100vh';
    if (height !== this._lastHeight) {
      this._lastResult = { height: height };
      this._lastHeight = height;
    }
    return this._lastResult;
  }

  ngOnInit() {
    this.isDesktopService.isDesktop$.pipe(
      tap(isDesktop => this.isDesktop = isDesktop),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    const navigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith({ url: this.router.url }),
      map(() => this.router.url),
      shareReplay(1)
    );

    this.isLandingPage$ = navigationEnd$.pipe(
      map(url => url.includes(FRAME_DATA.BRACKET_LANDING_PATH)),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.isLockPage$ = navigationEnd$.pipe(
      map(url => url.includes(FRAME_DATA.LOCK_PATH)),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.isSupportDVH = this.supportDvhService.checkDvhSupport();

    this.contentHeight$ = this.isLandingPage$.pipe(
      takeUntil(this.unsubscribe$),
      map((isLandingPage) => {
        return this.getHeight(isLandingPage);
      })
    )

    this.generateClassList();
  }

  generateClassList() {
    combineLatest([
      this.isDesktopService.isDesktop$,
      this.isLandingPage$,
      this.isLockPage$
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([
                    isDesktop,
                    isLandingPage,
                    isLockPage
                  ]) => {
          const containerClassListArray = [];
          const globalScrollContainerClassListArray = [];
          const wrapperScrollContainerClassListArray = [];

          if (isDesktop) {
            containerClassListArray.push('desktop');
            wrapperScrollContainerClassListArray.push('desktop');
          } else {
            if (this.platform.IOS && !this.isSupportDVH && isLandingPage) {
              globalScrollContainerClassListArray.push('global-scroll-container-ios');
            }
            if (this.platform.ANDROID) {
              globalScrollContainerClassListArray.push('global-scroll-container-android');
            }
          }

          if (isLandingPage) {
            containerClassListArray.push('landing');
            wrapperScrollContainerClassListArray.push('landing');
          } else if (isLockPage) {
            containerClassListArray.push('lock');
            wrapperScrollContainerClassListArray.push('lock');
          } else {
            globalScrollContainerClassListArray.push('global-scroll-container');
            wrapperScrollContainerClassListArray.push('scroll-container');
          }

          const newContainerClassList = containerClassListArray.join(' ');
          const newGlobalScrollContainerClassList = globalScrollContainerClassListArray.join(' ');
          const newWrapperScrollContainerClassListArray = wrapperScrollContainerClassListArray.join(' ');
          if (newContainerClassList !== this.containerClassList) {
            this.containerClassList = newContainerClassList;
          }
          if (newGlobalScrollContainerClassList !== this.globalScrollContainerClassList) {
            this.globalScrollContainerClassList = newGlobalScrollContainerClassList;
          }
          if (newWrapperScrollContainerClassListArray !== this.wrapperScrollContainerClassList) {
            this.wrapperScrollContainerClassList = newWrapperScrollContainerClassListArray;
          }
        }
      );
  }


  getHeight (isLandingPage) {
    const height = this.isSupportDVH ? '100dvh' : '100vh';
    return isLandingPage ? '' : height;
  }

  onOpenInfoModal() {
    this.modalWindowService.openInfoModal();
  }
}
