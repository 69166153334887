import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";

import { IsDesktopService } from "@common-services/is-desktop.services";
import {
  LandingBracketRoundInfoComponent
} from "@components/landing-bracket-round-info/landing-bracket-round-info.component";
import { LandingHowToPlayInfoComponent } from "@components/landing-how-to-play-info/landing-how-to-play-info.component";
import {
  LandingStreakRoundInfoComponent
} from "@components/landing-streak-round-info/landing-streak-round-info.component";
import { BracketGameService } from "@services/bracket-game.service";
import { CmsContentService } from "@services/cms-content.service";
import { MaintenanceControlService } from "@services/maintenance-control.service";

@Component({
  selector: 'hun-landing-bracket',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  imports: [
    AsyncPipe,
    LandingStreakRoundInfoComponent,
    LandingBracketRoundInfoComponent,
    LandingHowToPlayInfoComponent
  ],
  standalone: true
})
export class LandingComponent {
  constructor(public cmsContentService: CmsContentService,
              public isDesktopService: IsDesktopService,
              public bracketGameService: BracketGameService,
              public maintenanceControlService: MaintenanceControlService
  ) {
  }

}
