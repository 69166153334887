@if (cmsContentService.cmsContent$ | async; as cmsContent) {
  <div class="info-wrapper"
  >
    @if (info?.title) {
      <div class="title">
        {{ info.title }}
      </div>
    }
    @if (info.description) {
      <div class="content" [innerHTML]="info.description"></div>
    }
  </div>
}
