@if ((cmsContentService.cmsContent$ | async); as cmsContentConfig) {
  <div class="container"
       [ngStyle]="{'height': isSupportDVH ? '100dvh' : '100vh'}"
       [ngClass]="containerClassList">
    <div
      [ngStyle]="containerHeightStyle"
      [ngClass]="globalScrollContainerClassList"
    >
      <div
        infinite-scroll
        [scrollWindow]="false"
        (scrolled)="scrollService.infiniteScrollAction$.next(true)"
        [ngClass]="wrapperScrollContainerClassList"
        class="wrapper">
        <div class="content"
             [ngStyle]="{
             'height': contentHeight$ | async}">
          @if (!hideInfoButton && (isLandingPage$ | async)) {
            <button class="info" (click)="onOpenInfoModal()">
              <div>
                {{  cmsContentConfig['text_content']['iframe-v3-bracket-info-popup-title'] }}
              </div>
              <img alt="info-icon" class="info-icon" [src]="cmsContentConfig['image_content']['iframe-v3-bracket-info-popup-icon']">
            </button>
          }
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
}
