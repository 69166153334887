@if (cmsContentService.cmsContent$ | async; as cmsContent) {
  <div class="bracket-round-info-wrapper"
    >
    <div class="header-message">
      {{ cmsContent.text_content['iframe-v3-bracket-header-message-start-word'] }}
    </div>

    <div
      class="prize-section">
      {{ prizeAmount }}
    </div>

    <hun-base-button
      class="game-button"
      [disabled]="bracketGameService.isBracketNotOpened(roundInfo) || bracketGameService.isBracketNotPlayedAndClosedGroups(roundInfo) || bracketGameService.isBracketNotPlayedAndClosedKnockouts(roundInfo)"
      [bgColor]="roundInfo['gameButtonColor']"
      [color]="roundInfo['gameButtonTextColor']"
      [borderColor]="roundInfo['gameButtonBorderColor']"
      [fontSize]="'16px'"
      [lineHeight]="'20px'"
      (handleClick)="onRoundClick()"
      >
      <div class="button-content">
        {{roundInfo['gameButtonText']}}
      </div>
    </hun-base-button>
    <div class="timer-round-wrapper">
      @if (roundInfo[activeType].status === GameStatus.CLOSED) {
        <img [src]="cmsContent['image_content']['iframe-v3-bracket-timer-icon-landing-in-play']">
      } @else {
        <img [src]="cmsContent['image_content']['iframe-v3-bracket-timer-icon-landing']">
      }
      @switch (roundInfo[activeType].status) {
        @case (GameStatus.OPENED) {
          @if (activeType === BracketTypeEnum.GROUP) {
            @if (roundInfo[activeType].hasPlayed) {
              <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-closes-in']}}</div>
            } @else {
              <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-group-stage-closing-in']}}</div>
            }
          } @else if (activeType === BracketTypeEnum.KNOCKOUT) {
            @if (roundInfo[activeType].hasPlayed) {
              <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-closes-in']}}</div>
            } @else {
              <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-knockout-stage-closing-in']}}</div>
            }
          }
          <hun-timer [date]="roundInfo[activeType].closeDate" [isCloseTime]="true" [showMinutes]="true"></hun-timer>
        }
        @case (GameStatus.PENDING) {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-landing-opens-in']}}</div>
          <hun-timer [date]="roundInfo[activeType].openDate" [showMinutes]="true"></hun-timer>
        }
        @case (GameStatus.COMPLETED) {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-landing-round-closed']}}</div>
        }
        @default {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-bracket-landing-in-play']}}</div>
        }
      }
    </div>
  </div>
}
